import appActionTypes from '../constants/types'

export const initialState = {
  data: [],
  isLoading: false,
  message: null,
  errorMessage: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case appActionTypes.cy_program_sub_batch_session.get.requested:
    case appActionTypes.cy_program_sub_batch_session.create.requested:
    case appActionTypes.cy_program_sub_batch_session.delete.requested:
    case appActionTypes.cy_program_sub_batch_session.edit.requested:
    case appActionTypes.cy_program_sub_batch_session.generate.requested:
    case appActionTypes.cy_program_sub_batch_session.movetos3.requested:
    case appActionTypes.cy_program_sub_batch_session.deleteallrecordings
      .requested:
    case appActionTypes.cy_program_sub_batch_session.filter.requested:
    case appActionTypes.cy_program_sub_batch_session.deletefroms3.requested:
      return {
        ...state,
        isLoading: true,
        message: action.message,
        errorMessage: null,
      }
    case appActionTypes.cy_program_sub_batch_session.get.success:
    case appActionTypes.cy_program_sub_batch_session.filter.success:
      return {
        ...state,
        data: action.data,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      }
    case appActionTypes.cy_program_sub_batch_session.generate.success:
      let _newdata = [...state.data, ...action.data]
      return {
        ...state,
        data: _newdata,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      }
    case appActionTypes.cy_program_sub_batch_session.create.success:
      var _list = JSON.parse(JSON.stringify(state.data))
      _list.push(action.data)
      return {
        ...state,
        data: _list,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      }

    case appActionTypes.cy_program_sub_batch_session.delete.success:
      var _list = JSON.parse(JSON.stringify(state.data))
      var index = _list.findIndex((obj) => obj._id === action.data?._id)
      if (index !== -1) _list.splice(index, 1)

      return {
        ...state,
        data: _list,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      }

    case appActionTypes.cy_program_sub_batch_session.edit.success:
    case appActionTypes.cy_program_sub_batch_session.movetos3.success:
    case appActionTypes.cy_program_sub_batch_session.deleteallrecordings
      .success:
    case appActionTypes.cy_program_sub_batch_session.deletefroms3.success:
      var _list = JSON.parse(JSON.stringify(state.data))
      var index = _list.findIndex((obj) => obj._id === action.data?._id)
      if (index !== -1) _list[index] = action.data

      return {
        ...state,
        data: _list,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      }

    case appActionTypes.cy_program_sub_batch_session.get.fail:
    case appActionTypes.cy_program_sub_batch_session.create.fail:
    case appActionTypes.cy_program_sub_batch_session.delete.fail:
    case appActionTypes.cy_program_sub_batch_session.edit.fail:
    case appActionTypes.cy_program_sub_batch_session.generate.fail:
    case appActionTypes.cy_program_sub_batch_session.movetos3.fail:
    case appActionTypes.cy_program_sub_batch_session.deleteallrecordings.fail:
    case appActionTypes.cy_program_sub_batch_session.filter.fail:
    case appActionTypes.cy_program_sub_batch_session.deletefroms3.fail:
      return {
        ...state,
        isLoading: false,
        message: null,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}
