import Navigation from './layout/navigation'
import { BrowserRouter as Router } from 'react-router-dom'

import 'video-react/dist/video-react.css'
import 'antd/dist/antd.css'
import './App.css'

function App() {
  return (
    <Router>
      <Navigation />
    </Router>
  )
}

export default App
