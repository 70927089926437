import appActionTypes from "../constants/types";

export const initialState = {
    user: null,
    isLoading: false,
    message: null,
    errorMessage: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case appActionTypes.auth.register.requested:
            return {
                ...state,
                user: action.data,
                isLoading: true,
                message: action.message,
                errorMessage: null,
            };
        case appActionTypes.auth.register.success:
            return {
                ...state,
                user: action.data,
                isLoading: false,
                message: action.message
            };
        case appActionTypes.auth.register.fail:
            return {
                ...state,
                user: action.data,
                isLoading: false,
                message: action.message,
                errorMessage: action.errorMessage,
            };
        case appActionTypes.auth.login.requested:
            return {
                ...state,
                user: action.data,
                isLoading: true,
                message: action.message,
                errorMessage: null,
            };
        case appActionTypes.auth.login.success:
            return {
                ...state,
                user: action.data,
                isLoading: false,
                message: action.message
            };
        case appActionTypes.auth.login.fail:
            return {
                ...state,
                user: action.data,
                isLoading: false,
                message: action.message,
                errorMessage: action.errorMessage,
            };
        default:
            return state;
    }
};