import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import AppState from './AppState';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './utils/errorBoundry';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './services/reducers';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

// load all css
import './index.css';
import './common/css/common.css';

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
const store = createStoreWithMiddleware(rootReducer);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <AppState>
          <App />
        </AppState>
      </Provider>
    </ErrorBoundary>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
