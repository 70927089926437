import React, { lazy, useContext, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AuthContext } from '../AppState'
import Path from '../shared/constant/paths'
import WithRouter from '../shared/hocs/withrouter'
import { Spin } from 'antd'
import ScreenRoute from './routes'

//layouts
const DashbordLayout = lazy(() => import('./layout.dashboard'))
const PageNotFound = lazy(() => import('../shared/component/pagenotfound'))

function Navigation(props) {
  return (
    <Suspense fallback={<FallbackUI />}>
      <Routes>
        {ScreenRoute?.map((screen) => {
          var Screen = screen.element
          return (
            <Route
              key={screen.key}
              exact
              path={screen.path}
              element={
                screen?.ispublic ? (
                  <Screen {...props} />
                ) : (
                  <PrivateRoute Component={Screen} {...props} />
                )
              }
            />
          )
        })}
        <Route key="page404" path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  )
}

function PrivateRoute(props) {
  const { Component } = props
  const { getUser } = useContext(AuthContext)
  const user = getUser()

  if (!user) {
    return (
      <Navigate
        to={{
          pathname: Path.LOGIN,
          state: { from: props.location },
        }}
      />
    )
  } else {
    const Privatecomponent = WithRouter(Component)
    return (
      <DashbordLayout {...props}>
        <Privatecomponent />
      </DashbordLayout>
    )
  }
}

function FallbackUI() {
  return (
    <div className="loaderspin">
      <Spin tip="Loading..." />
    </div>
  )
}

export default WithRouter(Navigation)
