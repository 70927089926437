export default {
  auth: {
    register: {
      requested: 'AUTH_REGISTER_REQUESTED',
      success: 'AUTH_REGISTER_SUCCESS',
      fail: 'AUTH_REGISTER_FAIL',
    },
    login: {
      requested: 'AUTH_LOGIN_REQUESTED',
      success: 'AUTH_LOGIN_SUCCESS',
      fail: 'AUTH_LOGIN_FAIL',
    },
  },
  user: {
    get: {
      requested: 'USER_GET_REQUESTED',
      success: 'USER_GET_SUCCESS',
      fail: 'USER_GET_FAIL',
    },
    create: {
      requested: 'USER_CREATE_REQUESTED',
      success: 'USER_CREATE_SUCCESS',
      fail: 'USER_CREATE_FAIL',
    },
    edit: {
      requested: 'USER_CREATE_REQUESTED',
      success: 'USER_CREATE_SUCCESS',
      fail: 'USER_CREATE_FAIL',
    },
  },
  company: {
    get: {
      requested: 'COMPANY_GET_REQUESTED',
      success: 'COMPANY_GET_SUCCESS',
      fail: 'COMPANY_GET_FAIL',
    },
    create: {
      requested: 'COMPANY_CREATE_REQUESTED',
      success: 'COMPANY_CREATE_SUCCESS',
      fail: 'COMPANY_CREATE_FAIL',
    },
    edit: {
      requested: 'COMPANY_EDIT_REQUESTED',
      success: 'COMPANY_EDIT_SUCCESS',
      fail: 'COMPANY_EDIT_FAIL',
    },
    delete: {
      requested: 'COMPANY_DELETE_REQUESTED',
      success: 'COMPANY_DELETE_SUCCESS',
      fail: 'COMPANY_DELETE_FAIL',
    },
  },
  product: {
    get: {
      requested: 'PRODUCT_GET_REQUESTED',
      success: 'PRODUCT_GET_SUCCESS',
      fail: 'PRODUCT_GET_FAIL',
    },
    create: {
      requested: 'PRODUCT_CREATE_REQUESTED',
      success: 'PRODUCT_CREATE_SUCCESS',
      fail: 'PRODUCT_CREATE_FAIL',
    },
    edit: {
      requested: 'PRODUCT_EDIT_REQUESTED',
      success: 'PRODUCT_EDIT_SUCCESS',
      fail: 'PRODUCT_EDIT_FAIL',
    },
    delete: {
      requested: 'PRODUCT_DELETE_REQUESTED',
      success: 'PRODUCT_DELETE_SUCCESS',
      fail: 'PRODUCT_DELETE_FAIL',
    },
  },
  product_channel: {
    get: {
      requested: 'PRODUCT_CHANNEL_GET_REQUESTED',
      success: 'PRODUCT_CHANNEL_GET_SUCCESS',
      fail: 'PRODUCT_CHANNEL_GET_FAIL',
    },
    create: {
      requested: 'PRODUCT_CHANNEL_CREATE_REQUESTED',
      success: 'PRODUCT_CHANNEL_CREATE_SUCCESS',
      fail: 'PRODUCT_CHANNEL_CREATE_FAIL',
    },
    edit: {
      requested: 'PRODUCT_CHANNEL_EDIT_REQUESTED',
      success: 'PRODUCT_CHANNEL_EDIT_SUCCESS',
      fail: 'PRODUCT_CHANNEL_EDIT_FAIL',
    },
    delete: {
      requested: 'PRODUCT_CHANNEL_DELETE_REQUESTED',
      success: 'PRODUCT_CHANNEL_DELETE_SUCCESS',
      fail: 'PRODUCT_CHANNEL_DELETE_FAIL',
    },
  },
  customer: {
    get: {
      requested: 'CUSTOMER_GET_REQUESTED',
      success: 'CUSTOMER_CHANNEL_GET_SUCCESS',
      fail: 'CUSTOMER_CHANNEL_GET_FAIL',
    },
    create: {
      requested: 'CUSTOMER_CHANNEL_CREATE_REQUESTED',
      success: 'CUSTOMER_CHANNEL_CREATE_SUCCESS',
      fail: 'CUSTOMER_CHANNEL_CREATE_FAIL',
    },
    edit: {
      requested: 'CUSTOMER_CHANNEL_EDIT_REQUESTED',
      success: 'CUSTOMER_CHANNEL_EDIT_SUCCESS',
      fail: 'CUSTOMER_CHANNEL_EDIT_FAIL',
    },
    delete: {
      requested: 'CUSTOMER_CHANNEL_DELETE_REQUESTED',
      success: 'CUSTOMER_CHANNEL_DELETE_SUCCESS',
      fail: 'CUSTOMER_CHANNEL_DELETE_FAIL',
    },
  },
  customer_email_domain: {
    get: {
      requested: 'CUSTOMER_EMAIL_GET_REQUESTED',
      success: 'CUSTOMER_EMAIL_CHANNEL_GET_SUCCESS',
      fail: 'CUSTOMER_EMAIL_CHANNEL_GET_FAIL',
    },
    create: {
      requested: 'CUSTOMER_EMAIL_CHANNEL_CREATE_REQUESTED',
      success: 'CUSTOMER_EMAIL_CHANNEL_CREATE_SUCCESS',
      fail: 'CUSTOMER_EMAIL_CHANNEL_CREATE_FAIL',
    },
    edit: {
      requested: 'CUSTOMER_EMAIL_CHANNEL_EDIT_REQUESTED',
      success: 'CUSTOMER_EMAIL_CHANNEL_EDIT_SUCCESS',
      fail: 'CUSTOMER_EMAIL_CHANNEL_EDIT_FAIL',
    },
    delete: {
      requested: 'CUSTOMER_EMAIL_CHANNEL_DELETE_REQUESTED',
      success: 'CUSTOMER_EMAIL_CHANNEL_DELETE_SUCCESS',
      fail: 'CUSTOMER_EMAIL_CHANNEL_DELETE_FAIL',
    },
  },
  expert: {
    set: {
      requested: 'EXPERT_SET_TABLE_INFO',
    },
    get: {
      requested: 'EXPERT_GET_REQUESTED',
      success: 'EXPERT_GET_SUCCESS',
      fail: 'EXPERT_GET_FAIL',
    },
    create: {
      requested: 'EXPERT_CREATE_REQUESTED',
      success: 'EXPERT_CREATE_SUCCESS',
      fail: 'EXPERT_CREATE_FAIL',
    },
    edit: {
      requested: 'EXPERT_EDIT_REQUESTED',
      success: 'EXPERT_EDIT_SUCCESS',
      fail: 'EXPERT_EDIT_FAIL',
    },
    delete: {
      requested: 'EXPERT_DELETE_REQUESTED',
      success: 'EXPERT_DELETE_SUCCESS',
      fail: 'EXPERT_DELETE_FAIL',
    },
    get_all: {
      requested: 'EXPERT_ALL_GET_REQUESTED',
      success: 'EXPERT_ALL_GET_SUCCESS',
      fail: 'EXPERT_ALL_GET_FAIL',
    },
  },
  partner: {
    get: {
      requested: 'PARTNER_GET_REQUESTED',
      success: 'PARTNER_GET_SUCCESS',
      fail: 'PARTNER_GET_FAIL',
    },
    create: {
      requested: 'PARTNER_CREATE_REQUESTED',
      success: 'PARTNER_CREATE_SUCCESS',
      fail: 'PARTNER_CREATE_FAIL',
    },
    edit: {
      requested: 'PARTNER_EDIT_REQUESTED',
      success: 'PARTNER_EDIT_SUCCESS',
      fail: 'PARTNER_EDIT_FAIL',
    },
    delete: {
      requested: 'PARTNER_DELETE_REQUESTED',
      success: 'PARTNER_DELETE_SUCCESS',
      fail: 'PARTNER_DELETE_FAIL',
    },
  },
  cust_pro_mapping: {
    get: {
      requested: 'CP_MAPPING_GET_REQUESTED',
      success: 'CP_MAPPING_GET_SUCCESS',
      fail: 'CP_MAPPING_GET_FAIL',
    },
    create: {
      requested: 'CP_MAPPING_CREATE_REQUESTED',
      success: 'CP_MAPPING_CREATE_SUCCESS',
      fail: 'CP_MAPPING_CREATE_FAIL',
    },
    edit: {
      requested: 'CP_MAPPING_EDIT_REQUESTED',
      success: 'CP_MAPPING_EDIT_SUCCESS',
      fail: 'CP_MAPPING_EDIT_FAIL',
    },
    delete: {
      requested: 'CP_MAPPING_DELETE_REQUESTED',
      success: 'CP_MAPPING_DELETE_SUCCESS',
      fail: 'CP_MAPPING_DELETE_FAIL',
    },
  },
  offering: {
    get: {
      requested: 'OFFERING_GET_REQUESTED',
      success: 'OFFERING_GET_SUCCESS',
      fail: 'OFFERING_GET_FAIL',
    },
    create: {
      requested: 'OFFERING_CREATE_REQUESTED',
      success: 'OFFERING_CREATE_SUCCESS',
      fail: 'OFFERING_CREATE_FAIL',
    },
    edit: {
      requested: 'OFFERING_EDIT_REQUESTED',
      success: 'OFFERING_EDIT_SUCCESS',
      fail: 'OFFERING_EDIT_FAIL',
    },
    delete: {
      requested: 'OFFERING_DELETE_REQUESTED',
      success: 'OFFERING_DELETE_SUCCESS',
      fail: 'OFFERING_DELETE_FAIL',
    },
  },
  cy_program: {
    get: {
      requested: 'CY_PROGRAM_GET_REQUESTED',
      success: 'CY_PROGRAM_GET_SUCCESS',
      fail: 'CY_PROGRAM_GET_FAIL',
    },
    create: {
      requested: 'CY_PROGRAM_CREATE_REQUESTED',
      success: 'CY_PROGRAM_CREATE_SUCCESS',
      fail: 'CY_PROGRAM_CREATE_FAIL',
    },
    edit: {
      requested: 'CY_PROGRAM_EDIT_REQUESTED',
      success: 'CY_PROGRAM_EDIT_SUCCESS',
      fail: 'CY_PROGRAM_EDIT_FAIL',
    },
    delete: {
      requested: 'CY_PROGRAM_DELETE_REQUESTED',
      success: 'CY_PROGRAM_DELETE_SUCCESS',
      fail: 'CY_PROGRAM_DELETE_FAIL',
    },
  },
  cy_program_batch: {
    set: {
      requested: 'PROGRAM_BATCH_SET_TABLE_INFO',
    },
    get: {
      requested: 'PROGRAM_BATCH_GET_REQUESTED',
      success: 'PROGRAM_BATCH_GET_SUCCESS',
      fail: 'PROGRAM_BATCH_GET_FAIL',
    },
    create: {
      requested: 'PROGRAM_BATCH_CREATE_REQUESTED',
      success: 'PROGRAM_BATCH_CREATE_SUCCESS',
      fail: 'PROGRAM_BATCH_CREATE_FAIL',
    },
    edit: {
      requested: 'PROGRAM_BATCH_EDIT_REQUESTED',
      success: 'PROGRAM_BATCH_EDIT_SUCCESS',
      fail: 'PROGRAM_BATCH_EDIT_FAIL',
    },
    delete: {
      requested: 'PROGRAM_BATCH_DELETE_REQUESTED',
      success: 'PROGRAM_BATCH_DELETE_SUCCESS',
      fail: 'PROGRAM_BATCH_DELETE_FAIL',
    },
  },
  cy_program_batch_partner: {
    set: {
      requested: 'PROGRAM_BATCH_PARTNER_SET_TABLE_INFO',
    },
    get: {
      requested: 'PROGRAM_BATCH_PARTNER_GET_REQUESTED',
      success: 'PROGRAM_BATCH_PARTNER_GET_SUCCESS',
      fail: 'PROGRAM_BATCH_PARTNER_GET_FAIL',
    },
    create: {
      requested: 'PROGRAM_BATCH_PARTNER_CREATE_REQUESTED',
      success: 'PROGRAM_BATCH_PARTNER_CREATE_SUCCESS',
      fail: 'PROGRAM_BATCH_PARTNER_CREATE_FAIL',
    },
    edit: {
      requested: 'PROGRAM_BATCH_PARTNER_EDIT_REQUESTED',
      success: 'PROGRAM_BATCH_PARTNER_EDIT_SUCCESS',
      fail: 'PROGRAM_BATCH_PARTNER_EDIT_FAIL',
    },
    delete: {
      requested: 'PROGRAM_BATCH_PARTNER_DELETE_REQUESTED',
      success: 'PROGRAM_BATCH_PARTNER_DELETE_SUCCESS',
      fail: 'PROGRAM_BATCH_PARTNER_DELETE_FAIL',
    },
  },
  cy_program_batch_expert_mapping: {
    set: {
      requested: 'PROGRAM_BATCH_EXPERT_SET_TABLE_INFO',
    },
    get: {
      requested: 'PROGRAM_BATCH_EXPERT_GET_REQUESTED',
      success: 'PROGRAM_BATCH_EXPERT_GET_SUCCESS',
      fail: 'PROGRAM_BATCH_EXPERT_GET_FAIL',
    },
    create: {
      requested: 'PROGRAM_BATCH_EXPERT_CREATE_REQUESTED',
      success: 'PROGRAM_BATCH_EXPERT_CREATE_SUCCESS',
      fail: 'PROGRAM_BATCH_EXPERT_CREATE_FAIL',
    },
    edit: {
      requested: 'PROGRAM_BATCH_EXPERT_EDIT_REQUESTED',
      success: 'PROGRAM_BATCH_EXPERT_EDIT_SUCCESS',
      fail: 'PROGRAM_BATCH_EXPERT_EDIT_FAIL',
    },
    delete: {
      requested: 'PROGRAM_BATCH_EXPERT_DELETE_REQUESTED',
      success: 'PROGRAM_BATCH_EXPERT_DELETE_SUCCESS',
      fail: 'PROGRAM_BATCH_EXPERT_DELETE_FAIL',
    },
  },
  cy_program_batch_sub_batch: {
    set: {
      requested: 'PROGRAM_BATCH_SUB_BATCH_SET_TABLE_INFO',
    },
    get: {
      requested: 'PROGRAM_BATCH_SUB_BATCH_GET_REQUESTED',
      success: 'PROGRAM_BATCH_SUB_BATCH_GET_SUCCESS',
      fail: 'PROGRAM_BATCH_SUB_BATCH_GET_FAIL',
    },
    create: {
      requested: 'PROGRAM_BATCH_SUB_BATCH_CREATE_REQUESTED',
      success: 'PROGRAM_BATCH_SUB_BATCH_CREATE_SUCCESS',
      fail: 'PROGRAM_BATCH_SUB_BATCH_CREATE_FAIL',
    },
    edit: {
      requested: 'PROGRAM_BATCH_SUB_BATCH_EDIT_REQUESTED',
      success: 'PROGRAM_BATCH_SUB_BATCH_EDIT_SUCCESS',
      fail: 'PROGRAM_BATCH_SUB_BATCH_EDIT_FAIL',
    },
    delete: {
      requested: 'PROGRAM_BATCH_SUB_BATCH_DELETE_REQUESTED',
      success: 'PROGRAM_BATCH_SUB_BATCH_DELETE_SUCCESS',
      fail: 'PROGRAM_BATCH_SUB_BATCH_DELETE_FAIL',
    },
  },
  cy_program_sub_batch_session: {
    set: {
      requested: 'PROGRAM_BATCH_SUB_BATCH_SESSION_SET_TABLE_INFO',
    },
    get: {
      requested: 'PROGRAM_BATCH_SUB_BATCH_SESSION_GET_REQUESTED',
      success: 'PROGRAM_BATCH_SUB_BATCH_SESSION_GET_SUCCESS',
      fail: 'PROGRAM_BATCH_SUB_BATCH_SESSION_GET_FAIL',
    },
    create: {
      requested: 'PROGRAM_BATCH_SUB_BATCH_SESSION_CREATE_REQUESTED',
      success: 'PROGRAM_BATCH_SUB_BATCH_SESSION_CREATE_SUCCESS',
      fail: 'PROGRAM_BATCH_SUB_BATCH_SESSION_CREATE_FAIL',
    },
    edit: {
      requested: 'PROGRAM_BATCH_SUB_BATCH_SESSION_EDIT_REQUESTED',
      success: 'PROGRAM_BATCH_SUB_BATCH_SESSION_EDIT_SUCCESS',
      fail: 'PROGRAM_BATCH_SUB_BATCH_SESSION_EDIT_FAIL',
    },
    delete: {
      requested: 'PROGRAM_BATCH_SUB_BATCH_SESSION_DELETE_REQUESTED',
      success: 'PROGRAM_BATCH_SUB_BATCH_SESSION_DELETE_SUCCESS',
      fail: 'PROGRAM_BATCH_SUB_BATCH_SESSION_DELETE_FAIL',
    },
    generate: {
      requested: 'PROGRAM_BATCH_SUB_BATCH_SESSION_GENERATE_REQUESTED',
      success: 'PROGRAM_BATCH_SUB_BATCH_SESSION_GENERATE_SUCCESS',
      fail: 'PROGRAM_BATCH_SUB_BATCH_SESSION_GENERATE_FAIL',
    },
    movetos3: {
      requested: 'PROGRAM_BATCH_SUB_BATCH_MOVE_TO_S3_REQUESTED',
      success: 'PROGRAM_BATCH_SUB_BATCH_MOVE_TO_S3_SUCCESS',
      fail: 'PROGRAM_BATCH_SUB_BATCH_MOVE_TO_S3_FAIL',
    },
    deleteallrecordings: {
      requested: 'PROGRAM_BATCH_SUB_BATCH_DELETE_RECORDINGS_REQUESTED',
      success: 'PROGRAM_BATCH_SUB_BATCH_DELETE_RECORDINGS_SUCCESS',
      fail: 'PROGRAM_BATCH_SUB_BATCH_DELETE_RECORDINGS_FAIL',
    },
    filter: {
      requested: 'PROGRAM_BATCH_SUB_BATCH_FILTER_REQUESTED',
      success: 'PROGRAM_BATCH_SUB_BATCH_FILTER_SUCCESS',
      fail: 'PROGRAM_BATCH_SUB_BATCH_FILTER_FAIL',
    },
    deletefroms3: {
      requested: 'PROGRAM_BATCH_SUB_BATCH_REMOVE_RECORDING_FROM_S3_REQUESTED',
      success: 'PROGRAM_BATCH_SUB_BATCH_REMOVE_RECORDING_FROM_S3_SUCCESS',
      fail: 'PROGRAM_BATCH_SUB_BATCH_REMOVE_RECORDING_FROM_S3_FAIL',
    },
  },
  cy_customer_offering_mapping: {
    set: {
      requested: 'CUSTOMER_OFFERING_MAPPING_SET_TABLE_INFO',
    },
    get: {
      requested: 'CUSTOMER_OFFERING_MAPPING_GET_REQUESTED',
      success: 'CUSTOMER_OFFERING_MAPPING_GET_SUCCESS',
      fail: 'CUSTOMER_OFFERING_MAPPING_GET_FAIL',
    },
    create: {
      requested: 'CUSTOMER_OFFERING_MAPPING_CREATE_REQUESTED',
      success: 'CUSTOMER_OFFERING_MAPPING_CREATE_SUCCESS',
      fail: 'CUSTOMER_OFFERING_MAPPING_CREATE_FAIL',
    },
    edit: {
      requested: 'CUSTOMER_OFFERING_MAPPING_EDIT_REQUESTED',
      success: 'CUSTOMER_OFFERING_MAPPING_EDIT_SUCCESS',
      fail: 'CUSTOMER_OFFERING_MAPPING_EDIT_FAIL',
    },
    delete: {
      requested: 'CUSTOMER_OFFERING_MAPPING_DELETE_REQUESTED',
      success: 'CUSTOMER_OFFERING_MAPPING_DELETE_SUCCESS',
      fail: 'CUSTOMER_OFFERING_MAPPING_DELETE_FAIL',
    },
  },
  form: {
    survey: {
      get: {
        requested: 'FORM_SURVEY_GET_REQUESTED',
        success: 'FORM_SURVEY_GET_SUCCESS',
        fail: 'FORM_SURVEY_GET_FAIL',
      },
      add: {
        requested: 'FORM_SURVEY_ADD_REQUESTED',
        success: 'FORM_SURVEY_ADD_SUCCESS',
        fail: 'FORM_SURVEY_ADD_FAIL',
      },
      edit: {
        requested: 'FORM_SURVEY_EDIT_REQUESTED',
        success: 'FORM_SURVEY_EDIT_SUCCESS',
        fail: 'FORM_SURVEY_EDIT_FAIL',
      },
      delete: {
        requested: 'FORM_SURVEY_DELETE_REQUESTED',
        success: 'FORM_SURVEY_DELETE_SUCCESS',
        fail: 'FORM_SURVEY_DELETE_FAIL',
      },
    },
    survey_questions: {
      get: {
        requested: 'FORM_SURVEY_QUESTIONS_GET_REQUESTED',
        success: 'FORM_SURVEY_QUESTIONS_GET_SUCCESS',
        fail: 'FORM_SURVEY_QUESTIONS_GET_FAIL',
      },
      add: {
        requested: 'FORM_SURVEY_QUESTIONS_ADD_REQUESTED',
        success: 'FORM_SURVEY_QUESTIONS_ADD_SUCCESS',
        fail: 'FORM_SURVEY_QUESTIONS_ADD_FAIL',
      },
      delete: {
        requested: 'FORM_SURVEY_QUESTIONS_DELETE_REQUESTED',
        success: 'FORM_SURVEY_QUESTIONS_DELETE_SUCCESS',
        fail: 'FORM_SURVEY_QUESTIONS_DELETE_FAIL',
      },
      re_arrange: {
        requested: 'FORM_SURVEY_QUESTIONS_RE_ARRANGE_REQUESTED',
        success: 'FORM_SURVEY_QUESTIONS_RE_ARRANGE_SUCCESS',
        fail: 'FORM_SURVEY_QUESTIONS_RE_ARRANGE_FAIL',
      },
    },
  },
}
