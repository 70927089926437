import appActionTypes from "../constants/types";

export const initialState = {
  data: null,
  isLoading: false,
  message: null,
  errorMessage: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case appActionTypes.user.get.requested:
    case appActionTypes.user.create.requested:
    case appActionTypes.user.edit.requested:
      return {
        ...state,
        data: action.data,
        isLoading: true,
        message: action.message,
      };
    case appActionTypes.user.get.success:
    case appActionTypes.user.create.success:
    case appActionTypes.user.edit.success:
      return {
        ...state,
        data: action.data,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      };
    case appActionTypes.user.get.fail:
    case appActionTypes.user.create.fail:
    case appActionTypes.user.edit.fail:
      return {
        ...state,
        isLoading: false,
        message: null,
        errorMessage: action.message,
      };
    default:
      return state;
  }
};
