import React, { useState } from 'react'
import Path from './shared/constant/paths'

export const AuthContext = React.createContext()

function AppState(props) {
  const getUser = () => {
    const json = localStorage.getItem('user')
    if (!json) return undefined
    return JSON.parse(json)
  }

  const signIn = (user) => {
    localStorage.setItem('user', JSON.stringify(user))
  }

  const signOut = (history) => {
    localStorage.removeItem('user')
    history(Path.LOGIN)
  }

  return (
    <AuthContext.Provider
      value={{
        getUser,
        signIn,
        signOut,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export default AppState
