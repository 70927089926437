import appActionTypes from "../constants/types";

export const initialState = {
    data: null,
    questions_data: null,
    isLoading: false,
    message: null,
    errorMessage: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case appActionTypes.form.survey.get.requested:
        case appActionTypes.form.survey.add.requested:
        case appActionTypes.form.survey.edit.requested:
        case appActionTypes.form.survey.delete.requested:
            return {
                ...state,
                isLoading: true,
                message: action.message,
                errorMessage: null,
            };
        case appActionTypes.form.survey.get.success:
        case appActionTypes.form.survey.add.success:
        case appActionTypes.form.survey.edit.success:
        case appActionTypes.form.survey.delete.success:
            return {
                ...state,
                data: action.data,
                isLoading: false,
                message: action.message
            };
        case appActionTypes.form.survey.get.fail:
        case appActionTypes.form.survey.add.fail:
        case appActionTypes.form.survey.edit.fail:
        case appActionTypes.form.survey.delete.fail:
            return {
                ...state,
                isLoading: false,
                message: action.message,
                errorMessage: action.errorMessage,
            };
        case appActionTypes.form.survey_questions.get.requested:
        case appActionTypes.form.survey_questions.add.requested:
        case appActionTypes.form.survey_questions.delete.requested:
        case appActionTypes.form.survey_questions.re_arrange.requested:
            return {
                ...state,
                isLoading: true,
                message: action.message,
                errorMessage: null,
            };
        case appActionTypes.form.survey_questions.get.success:
        case appActionTypes.form.survey_questions.add.success:
        case appActionTypes.form.survey_questions.delete.success:
        case appActionTypes.form.survey_questions.re_arrange.success:
            return {
                ...state,
                questions_data: action.data,
                isLoading: false,
                message: action.message
            };
        case appActionTypes.form.survey_questions.get.fail:
        case appActionTypes.form.survey_questions.add.fail:
        case appActionTypes.form.survey_questions.delete.fail:
        case appActionTypes.form.survey_questions.re_arrange.fail:
            return {
                ...state,
                isLoading: false,
                message: action.message,
                errorMessage: action.errorMessage,
            };
        default:
            return state;
    }
};