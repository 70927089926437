import appActionTypes from "../constants/types";

export const initialState = {
  data: [],
  isLoading: false,
  message: null,
  errorMessage: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case appActionTypes.company.get.requested:
    case appActionTypes.company.create.requested:
    case appActionTypes.company.delete.requested:
    case appActionTypes.company.edit.requested:
      return {
        ...state,
        isLoading: true,
        message: action.message,
        errorMessage: null
      };
    case appActionTypes.company.get.success:
      return {
        ...state,
        data: action.data,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      };
      
    case appActionTypes.company.create.success:
      var _newlist = JSON.parse(JSON.stringify(state.data))
      _newlist.push(action.data);
      return {
        ...state,
        data: _newlist,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      };

    case appActionTypes.company.delete.success:
      var _newlist = JSON.parse(JSON.stringify(state.data))
      var index = _newlist.findIndex((obj) => obj._id === action.data?._id);
      if (index !== -1) _newlist.splice(index, 1);

      return {
        ...state,
        data: _newlist,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      };
      
    case appActionTypes.company.edit.success:
      var _newlist = JSON.parse(JSON.stringify(state.data))
      var index = _newlist.findIndex((obj) => obj._id === action.data?._id);
      if (index !== -1) _newlist[index] = action.data;

      return {
        ...state,
        data: _newlist,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      };
    case appActionTypes.company.get.fail:
    case appActionTypes.company.create.fail:
    case appActionTypes.company.delete.fail:
    case appActionTypes.company.edit.fail:
      return {
        ...state,
        isLoading: false,
        message: null,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};
