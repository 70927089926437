import { combineReducers } from 'redux'

// Import All State
import userState from './userReducer'
import companyState from './companyReducer'
import authState from './authReducer'
import productState from './productReducer'
import productChannelState from './product_channelReducer'
import customerState from './customerReducer'
import customerEmailState from './customer_email_domainReducer'
import expertState from './expertReducer'
import partnerState from './partnerReducer'
import custProMappingState from './customer_product_mappingReducer'
import offeringState from './offeringReducer'
import programState from './programReducer'
import programBatchState from './program_batchReducer'
import programBatchPartnerState from './program_batch_partner_mappingReduce'
import programBatchExpertState from './program_batch_expert_mappingReducer'
import programBatchSubBatchState from './program_batch_sub_batchReducer'
import subBatchSessionState from './sub_batch_sessionReducer'
import customerOffMappingState from './customer_offering_mappingReducer'
import formState from './formReducer'

export default combineReducers(
  Object.assign({
    userState,
    authState,
    companyState,
    productState,
    productChannelState,
    customerState,
    customerEmailState,
    expertState,
    partnerState,
    custProMappingState,
    offeringState,
    programState,
    programBatchState,
    programBatchPartnerState,
    programBatchExpertState,
    programBatchSubBatchState,
    subBatchSessionState,
    customerOffMappingState,
    formState
  }),
)
