import appActionTypes from '../constants/types'

export const initialState = {
  data: [],
  isLoading: false,
  message: null,
  errorMessage: null,
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
  },
  filters: null,
  sorter: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case appActionTypes.cy_program_batch.get.requested:
    case appActionTypes.cy_program_batch.create.requested:
    case appActionTypes.cy_program_batch.delete.requested:
    case appActionTypes.cy_program_batch.edit.requested:
      return {
        ...state,
        isLoading: true,
        message: action.message,
        errorMessage: null,
      }
    case appActionTypes.cy_program_batch.get.success:
      return {
        ...state,
        data: action.data?.docs,
        pagination: {
          ...state.pagination,
          page: action.data?.page,
          pageSize: action.data?.limit,
          total: action.data?.total,
        },
        sorter: action.data.sorter,
        filters: action.data.filters,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      }
    case appActionTypes.cy_program_batch.create.success:
    case appActionTypes.cy_program_batch.delete.success:
      return {
        ...state,
        data: action.data?.docs,
        pagination: {
          ...state.pagination,
          page: action.data?.page,
          pageSize: action.data?.limit,
          total: action.data?.total,
        },
        filters: null,
        sorter: null,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      }
    case appActionTypes.cy_program_batch.edit.success:
      var _list = JSON.parse(JSON.stringify(state.data))
      var index = _list.findIndex((obj) => obj._id === action.data?._id)
      if (index !== -1) _list[index] = action.data

      return {
        ...state,
        data: _list,
        filters: null,
        sorter: null,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      }
    case appActionTypes.cy_program_batch.get.fail:
    case appActionTypes.cy_program_batch.create.fail:
    case appActionTypes.cy_program_batch.delete.fail:
    case appActionTypes.cy_program_batch.edit.fail:
      return {
        ...state,
        isLoading: false,
        message: null,
        errorMessage: action.errorMessage,
      }
    case appActionTypes.cy_program_batch.set.requested:
      return {
        ...state,
        pagination: action.pagination,
        filters: action.filters,
        sorter: action.sorter,
      }
    default:
      return state
  }
}
