const Path = {
  DASHBOARD: '/dashbord',
  LOGIN: '/',
  SIGNUP: '/signup',
  COMPANY_SCREEN: '/company',
  PRODUCT_SCREEN: '/product',
  PRODUCT_CHANNEL_SCREEN: '/productchannel',
  CUSTOMER_SCREEN: '/customer',
  CUSTOMER_EMAIL_DOMAIN: '/customer_email_domain',
  EXPERT: '/expert',
  PARTNER_SCREEN: '/partner',
  CUST_PRO_MAPPING_SCREEN: '/customer_product_mapping',
  OFFERING_SCREEN: '/offerings',
  CUST_OFF_MAPPING_SCREEN: '/customer_offering_mapping',
  PROGRAM_SCREEN: '/program',
  PROGRAM_BATCH_SCREEN: '/program_batch',
  PROGRAM_BATCH_PARTNER_MAPPING_SCREEN: '/program_batch_partner_mapping',
  PROGRAM_BATCH_EXPERT_MAPPING_SCREEN: '/program_batch_expert_mapping',
  PROGRAM_BATCH_SUB_BATCH: '/program_batch_sub_batch',
  PROGRAM_BATCH_SUB_BATCH_SESSION: '/program_batch_sub_batch_session',
  MEETING_MASTER: '/meeting',
  SURVEY: '/survey',
}

export default Path
