import appActionTypes from '../constants/types'

export const initialState = {
  data: [],
  isLoading: false,
  message: null,
  errorMessage: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case appActionTypes.cust_pro_mapping.get.requested:
    case appActionTypes.cust_pro_mapping.create.requested:
    case appActionTypes.cust_pro_mapping.delete.requested:
    case appActionTypes.cust_pro_mapping.edit.requested:
      return {
        ...state,
        isLoading: true,
        message: action.message,
        errorMessage: null,
      }
    case appActionTypes.cust_pro_mapping.get.success:
      return {
        ...state,
        data: action.data,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      }

    case appActionTypes.cust_pro_mapping.create.success:
      var _list = JSON.parse(JSON.stringify(state.data))
      _list.push(action.data)
      return {
        ...state,
        data: _list,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      }

    case appActionTypes.cust_pro_mapping.delete.success:
      var _list = JSON.parse(JSON.stringify(state.data))
      var index = _list.findIndex((obj) => obj._id === action.data?._id)
      if (index !== -1) _list.splice(index, 1)

      return {
        ...state,
        data: _list,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      }

    case appActionTypes.cust_pro_mapping.edit.success:
      var _list = JSON.parse(JSON.stringify(state.data))
      var index = _list.findIndex((obj) => obj._id === action.data?._id)
      if (index !== -1) _list[index] = action.data

      return {
        ...state,
        data: _list,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      }
    case appActionTypes.cust_pro_mapping.get.fail:
    case appActionTypes.cust_pro_mapping.create.fail:
    case appActionTypes.cust_pro_mapping.delete.fail:
    case appActionTypes.cust_pro_mapping.edit.fail:
      return {
        ...state,
        isLoading: false,
        message: null,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}
