import { lazy } from 'react'
import Path from '../shared/constant/paths'
import {
  CrownOutlined,
  GiftOutlined,
  NodeIndexOutlined,
  UserOutlined,
  MailOutlined,
  TeamOutlined,
  PartitionOutlined,
  ShakeOutlined,
  RocketOutlined,
  ReadOutlined,
  ApartmentOutlined,
  ClusterOutlined,
  VideoCameraAddOutlined,
  FormOutlined
} from '@ant-design/icons'

//Import All Screen Here
const LoginScreen = lazy(() => import('../Components/auth/login.screen'))
const SignUpScreen = lazy(() => import('../Components/auth/signup.screen'))
const CompanyScreen = lazy(() => import('../Components/company/company.screen'))
const ProductScreen = lazy(() => import('../Components/product/product.screen'))
const ProductChannelScreen = lazy(() =>
  import('../Components/product_channel/product.channel.screen'),
)
const CustomerScreen = lazy(() =>
  import('../Components/customer/customer.screen'),
)
const CustomerEmailDomainScreen = lazy(() =>
  import('../Components/customer_email_domain/customer_email.screen'),
)
const ExpertScreen = lazy(() => import('../Components/expert/expert.screen'))
const PartnerScreen = lazy(() => import('../Components/partner/partner.screen'))
const ProgramScreen = lazy(() => import('../Components/program/program.screen'))
const CustProMappingScreen = lazy(() =>
  import(
    '../Components/customer_product_mapping/customer_product_mapping.screen'
  ),
)
const OfferingScreen = lazy(() =>
  import('../Components/offerings/offering.screen'),
)
const ProgramBatch = lazy(() =>
  import('../Components/program_batch/program_batch.screen'),
)
const ProgramBatchPartnerMappingScreen = lazy(() =>
  import(
    '../Components/program_batch_partner_mapping/program_batch_partner_mapping.screen'
  ),
)
const ProBatchExpMappingScreen = lazy(() =>
  import(
    '../Components/program_batch_expert_mapping/program_batch_expert_mapping.screen'
  ),
)

const ProBatSubBatScreen = lazy(() =>
  import(
    '../Components/program_batch_sub_batches/program_batch_sub_batches.screen'
  ),
)

const CustOffMappingScreen = lazy(() =>
  import(
    '../Components/customer_offfering_mapping/customer_offfering_mapping.screen'
  ),
)

const MeetingMasterScreen = lazy(() =>
  import('../Components/meetings/meeting.screen'))

const SurveyScreen = lazy(() =>
  import(
    '../Components/form/survey.builder.screen'
  ),
)

const AuthRoute = [
  {
    key: 'login',
    path: Path.LOGIN,
    ispublic: true,
    element: LoginScreen,
  },
  {
    key: 'signup',
    path: Path.SIGNUP,
    ispublic: true,
    element: SignUpScreen,
  },
]

export const PlatformMasterRoute = [
  {
    key: 'company',
    label: 'Company',
    path: Path.COMPANY_SCREEN,
    element: CompanyScreen,
    icon: <CrownOutlined />,
  },
  {
    key: 'product',
    label: 'Product',
    path: Path.PRODUCT_SCREEN,
    element: ProductScreen,
    icon: <GiftOutlined />,
  },
  {
    key: 'product_channel',
    label: 'Product Channel',
    path: Path.PRODUCT_CHANNEL_SCREEN,
    element: ProductChannelScreen,
    icon: <NodeIndexOutlined />,
  },
  {
    key: 'customer',
    label: 'Customer',
    path: Path.CUSTOMER_SCREEN,
    element: CustomerScreen,
    icon: <UserOutlined />,
  },
  {
    key: 'customer_email_domain',
    label: 'Customer Email Domain',
    path: Path.CUSTOMER_EMAIL_DOMAIN,
    element: CustomerEmailDomainScreen,
    icon: <MailOutlined />,
  },
  {
    key: 'cust_pro_mapping',
    label: 'Customer Product Mapping',
    path: Path.CUST_PRO_MAPPING_SCREEN,
    element: CustProMappingScreen,
    icon: <PartitionOutlined />,
  },
  {
    key: 'partner',
    label: 'Partner',
    path: Path.PARTNER_SCREEN,
    element: PartnerScreen,
    icon: <TeamOutlined />,
  },
  {
    key: 'expert',
    label: 'Expert',
    path: Path.EXPERT,
    element: ExpertScreen,
    icon: <UserOutlined />,
  },
]

export const HubMaster = [
  {
    key: 'offering',
    label: 'Offering',
    path: Path.OFFERING_SCREEN,
    element: OfferingScreen,
    icon: <ShakeOutlined />,
  },
  {
    key: 'customer_offering_mapping',
    label: 'Customer Offering Mapping',
    path: Path.CUST_OFF_MAPPING_SCREEN,
    element: CustOffMappingScreen,
    icon: <NodeIndexOutlined />,
  },
  {
    key: 'program',
    label: 'Program',
    path: Path.PROGRAM_SCREEN,
    element: ProgramScreen,
    icon: <RocketOutlined />,
  },
  {
    key: 'program_bacth',
    label: 'Program Batch',
    path: Path.PROGRAM_BATCH_SCREEN,
    element: ProgramBatch,
    icon: <ReadOutlined />,
  },
  {
    key: 'program_batch_sub_batch',
    label: 'Program Batch - Sub Batch',
    path: Path.PROGRAM_BATCH_SUB_BATCH,
    element: ProBatSubBatScreen,
    icon: <ClusterOutlined />,
  },
  {
    key: 'program_batch_partner_mapping',
    label: 'Program Sub Batch - Partner Mapping',
    path: Path.PROGRAM_BATCH_PARTNER_MAPPING_SCREEN,
    element: ProgramBatchPartnerMappingScreen,
    icon: <ApartmentOutlined />,
  },
  {
    key: 'program_batch_expert_mapping',
    label: 'Program Sub Batch - Expert Mapping',
    path: Path.PROGRAM_BATCH_EXPERT_MAPPING_SCREEN,
    element: ProBatchExpMappingScreen,
    icon: <ClusterOutlined />,
  },
]

export const MeetingMaster = [
  {
    key: 'meeting',
    label: 'Session Links',
    path: Path.MEETING_MASTER,
    element: MeetingMasterScreen,
    icon: <VideoCameraAddOutlined />,
  },
]


export const FormBuilder = [
  {
    key: 'survey_builder',
    label: 'Survey',
    path: Path.SURVEY,
    element: SurveyScreen,
    icon: <FormOutlined />,
  },
]

const ScreenRoute = [...AuthRoute, ...PlatformMasterRoute, ...HubMaster, ...MeetingMaster, ...FormBuilder]

export default ScreenRoute
