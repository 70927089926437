import appActionTypes from "../constants/types";

export const initialState = {
  data: [],
  isLoading: false,
  message: null,
  errorMessage: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case appActionTypes.customer.get.requested:
    case appActionTypes.customer.create.requested:
    case appActionTypes.customer.delete.requested:
    case appActionTypes.customer.edit.requested:
      return {
        ...state,
        isLoading: true,
        message: action.message,
        errorMessage: null
      };
    case appActionTypes.customer.get.success:
      return {
        ...state,
        data: action.data,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      };
      
    case appActionTypes.customer.create.success:
      var _list = JSON.parse(JSON.stringify(state.data))
      _list.push(action.data);
      return {
        ...state,
        data: _list,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      };

    case appActionTypes.customer.delete.success:
      var _list = JSON.parse(JSON.stringify(state.data))
      var index = _list.findIndex((obj) => obj._id === action.data?._id);
      if (index !== -1) _list.splice(index, 1);

      return {
        ...state,
        data: _list,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      };
      
    case appActionTypes.customer.edit.success:
      var _list = JSON.parse(JSON.stringify(state.data))
      var index = _list.findIndex((obj) => obj._id === action.data?._id);
      if (index !== -1) _list[index] = action.data;

      return {
        ...state,
        data: _list,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      };
    case appActionTypes.customer.get.fail:
    case appActionTypes.customer.create.fail:
    case appActionTypes.customer.delete.fail:
    case appActionTypes.customer.edit.fail:
      return {
        ...state,
        isLoading: false,
        message: null,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};
